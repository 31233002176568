<template>
     <div class="ks-page-header">
        <section class="ks-title">
            <h3>{{$route.meta.head}}</h3>
            <button class="btn btn-outline-primary ks-light ks-mail-navigation-block-toggle" data-block-toggle=".ks-mail > .ks-navigation">Menu</button>
        </section>
    </div>
    <div class="ks-page-content">
        <div class="ks-page-content-body ks-mail">
            <div class="ks-navigation ks-browse ks-scrollable" data-auto-height>
                <div class="ks-wrapper">
                    <div class="ks-separator">
                        Menu
                    </div>
                    <ul class="ks-menu">
                        <li>
                            <router-link to="/contacts" class="ks-menu-item" :class="{'ks-active': $route.name == 'all'}">
                                <span class="ks-text">All Contacts</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/contacts/me" class="ks-menu-item" :class="{'ks-active': $route.name == 'me'}">
                                <span class="ks-text">My Saved Contacts</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/contacts/duplicate" class="ks-menu-item" :class="{'ks-active': $route.name == 'duplicate'}">
                                <span class="ks-text">Duplicate Contacts</span>
                            </router-link>
                        </li>
                        <li>
                            <a class="ks-menu-item bg-success text-white cp" @click="$emitter.emit('contact_sidebar', false)">
                                <span class="ks-text">
                                    Create New Contact</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <router-view v-slot="{ Component }" name="contact_view">
                <keep-alive>
                    <component :is="Component" />
                </keep-alive>
            </router-view>
        </div>
    </div>
</template>

<script>

export default {
}
</script>

<style scoped>
@import url(/assets/styles/apps/mail.min.css);
</style>